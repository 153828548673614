.small {
	width: 36px;
    height: 36px;
}

.tiny {
	width: 24px;
    height: 24px;
}

.chat-msg:hover{
	background: #F5F5F5
}

.chat-msg:hover .chat-controls{
	display: block;
}

.chat-msg{
	margin-bottom: 0px;
	position: relative;
}

.chat-msg.rtl {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    align-items: stretch;
    justify-content: flex-end;
    padding-left: 170px;
    transform: translateX(0px);
    margin-bottom: 0;
}

.chat-msg.rtl .chat-msg-content{
	width:  auto;
}

.chat-msg .status{
	font-size: 11px;
    color: #777;
}

.chat-msg .status.failed{
    color: red;
}
.app_mesage_body .message_typing_area .inner-scrollbar {
    border: 1px solid #242424;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: auto;
    padding: 14px 14px 40px;
    resize: none;
    background: #FCFDFE;
    border: 1px solid #E9EEF3;
    box-shadow: inset 0px 1px 2px #ebf0f8;
    border-radius: 6px;
}
.app_mesage_body .message_typing_area .chat-input{
	    outline: none;
    height:auto;
    overflow-y: auto;
    color: grey;
}
.chat_detail .app_mesage_body .chat-area-main{
	height: calc(100% - 120px);
}
.message-attachements{
	display:flex;
	flex-wrap: wrap;
}
.message-attachements .attachment {
    border: 1px solid #c7c6c6;
    background: #e9e9e9;
    padding: 7px 10px;
    width: 300px;
    position: relative;
    display: inline-block;
    margin-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
    margin-right: 4px;
}
.attachment {
    border: 1px solid #c7c6c6;
    background: #e9e9e9;
    padding: 7px 10px;
    width: 300px;
    position: relative;
    display: inline-block;
    margin-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
    margin-right: 4px;
}
.attachment.files img {
    max-width: unset;
    width: 36px;
    cursor: pointer;
    height: 26px;
}
.message-attachements .attachment .progress-bar {
    height: 3px;
    background: #47dd47;
    position: absolute;
}
/*.app_mesage_body .chat-area-main {
    height: calc(100% - 70px);
}*/

a.delete-attachment {
    position: absolute;
    right: 10px;
}

.attachment-preview img{
	max-width: 250px;
    height: 250px;
    cursor: pointer;
    margin: 5px;
}

.attachment-preview {
    margin-right: 10px;
    display: flex;
    margin-right: 10px;
    flex-wrap: wrap;
    min-width: 100%;
}
.my-msg .attachment-preview {
    justify-content: flex-end;
}
.attachment-preview.two {
    /* display: flex; */
    margin-right: 10px;
}


.user_img img{
	width: 100%;
}

.chat-controls {
    position: absolute;
    right: 40px;
    border-radius: 7px;
    padding: 5px 10px;
    background: #dcdcdc;
    bottom: 10px;
    display: none;
}

.comment-wrapper{
	width: 100%;
	display: block;
	color: black;
}

#message-inputbox .comment-wrapper{
	border-left: 2px solid #c5c4c4;
    padding-left: 10px;
	/*pointer-events: none !important;
    -webkit-user-modify: read-only;*/
}

.comment-wrapper blockquote {
	border-left: 2px solid #c5c4c4;
    padding-left: 10px;
    margin: 0;
    /*background: #f3f3f3;
    padding: 10px;
    box-shadow: 1px 2px 3px 0px #c0c0c0;
    display: inline-block;*/
}
.message_typing_area .comment-wrapper blockquote{
	color: inherit !important;
}
.comment-wrapper blockquote .username, .comment-wrapper blockquote .time-ui{
	font-size: 12px;
	color: #333;
}
.comment-wrapper blockquote .username{
	float: left;
}
.comment-wrapper blockquote .time-ui{
	float: right;
}
.comment-wrapper blockquote .comment-message{
	font-size: 14px;
    width: 100%;
    clear: both;
    padding-top: 7px;
}

blockquote.comment{
	color: #500050;
}

@-o-keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fefed4
    }
    to {
        background: #fff
    }
}

@-webkit-keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fefed4
    }
    to {
        background: #fff
    }
}

@keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fefed4
    }
    to {
        background: #fff
    }
}

.highlight {
/*     box-shadow: 0 0 0 2px #FF4081; */
  -webkit-animation: highlightBg 1s linear;
  -o-animation: highlightBg 1s linear;
  animation: highlightBg 1s linear;
  -webkit-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1
}

aside.emoji-picker-react{
	visibility: hidden !important;
	position: absolute !important;
    bottom: 0 !important;
    box-shadow: 0 5px 10px #6d5e5e !important;
}

aside.emoji-picker-react.active{
	visibility: visible !important;
}

.chat-msg {
-webkit-transition: 0.2s ease-in-out;
-o-transition: 0.2s ease-in-out;
transition: 0.2s ease-in-out;
position: relative; }
.chat-msg:hover {
background: transparent; }

/* .chat-msg .chat-desc:hover + .action-btns {
opacity: 1;
visibility: visible; } */
.chat-msg .chat-reply-sec {
position: relative; }

/*******new css *******/
.chat-main-wrpr .chat_left_panel{
    min-height: 80vh;
}
.chat-main-wrpr section.chat-details-wrpr {
    min-height: 80vh;
}
.chat-main-wrpr .chat-details-wrpr .app_mesage_body{
    height: calc(100% - 74px);
}
.chat-msg.my-msg:last-child .msg-wrpr .action-btns .dropdown-menu {
    top: -180px !important;
}
.chat-msg.my-msg:first-child .msg-wrpr .action-btns .dropdown-menu {
    top: 0px !important;
}
div.chat-msg.my-msg:nth-child(2) .msg-wrpr .action-btns .dropdown-menu {
    top: inherit !important;
}
div.chat-msg.my-msg:nth-child(3) .msg-wrpr .action-btns .dropdown-menu {
    top: inherit !important;
}
/*
    css for chat bottom align
.chat-main-wrpr section.chat-details-wrpr .chat-area-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
*/
.chat-msg.my-msg .chat-msg-content .user-detail {
    padding-right: 0;
}
.user-list-item span.user-img-name {
    display: flex;
    align-items: flex-start;
    align-items: center;
}
.name-colab span.last_message{
    margin-left: 0px;
}

.chat-msg .msg-wrpr{
    max-width: 100%;
}
.chat-msg .msg-wrpr .action-btns{
    left: auto;
    right: 0;
    max-width: 215px;
}

.chat-msg.my-msg .msg-wrpr .action-btns{
    right: 0;
    left: initial;
}
div.chat-msg .action-btns .btn:hover{
    background-color: transparent;
}

.chat-msg .msg-wrpr .action-btns .more-dropdown.dropdown {
    padding-left: 8px;
    padding-right: 8px;
}
.chat-msg .msg-wrpr .action-btns .more-dropdown.dropdown img {
    width: 5px !important;  
    transform: rotate(90deg);
}

.chat-msg.my-msg .chat-msg-content .chat-desc{
    max-width: 100%;
}

.frwd-modal .modal-dialog .modal-body .user-list-wrpr ul.user-list {
    min-height: 400px;
}
.chat-msg .status.edited {
    text-transform: inherit;
    padding: 0;
    margin-top: 0 !important;
    margin-bottom: 3px;
    font-weight: 100;
}
.chat-msg.my-msg .chat-reactions{
    position: relative;
    right: 0;
    top: -3px;
    margin-left: 10px;
}
.chat-msg.my-msg .chat-reactions span:first-child{
    margin-left: 0px !important;
}
.chat-msg.my-msg .chat-reactions span:last-child{
    margin-left: 3px;
}
.chat-reactions .chat_noti_reaction{
    margin-left: 3px;
    display: inline-flex;
    overflow: hidden;
    cursor: pointer;
}

.chat-reactions .chat_noti_reaction img{
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.chat-msg .chat-reactions {
    position: relative;
    right: 0;
    margin-left: 10px;
    top: -2px;
}
.chat-msg .chat-reactions span:first-child{
    margin-left: 0px !important;
}
.chat-msg .chat-reactions span:last-child{
    margin-left: 3px;
}

.replyChat {
    background: #f9f6f6;
    border: 1px solid #eee;
    border-radius: 2px;
    position: relative;
    padding-left: 20px;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
}
.replyChat:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 90%;
    background: #cbc7c7;
    left: 3px;
    top: 5%;
    border-radius: 5px;
}
.replyChat .user-name {
    font-size: 13px;
}
.replyChat .time-ui {
    font-size: 12px;margin-left: 15px;
}
.replyChat .comment {
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.reply-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-family: "Nunito Sans", sans-serif !important;
    font-weight: 100;
    font-size: 20px;
    color: #a4a0a0;
    margin-right: 2px;
    margin-top: 4px;
}
.reply-close:hover{
    background-color: transparent;
}

/**************End new css *********/

.chat-msg .action-btns {
	z-index: 1;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
border-radius: 7px;
position: absolute;
right: 0%;
opacity: 0;
visibility: hidden;
max-height: 40px;
-webkit-transition: 0.2s ease-in-out;
-o-transition: 0.2s ease-in-out;
transition: 0.2s ease-in-out; }
.chat-msg .action-btns .btn {
position: relative;
display: -webkit-inline-box;
display: -ms-inline-flexbox;
display: inline-flex;
padding: 4px 4px;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
text-decoration: none; }
.chat-msg .action-btns .btn:hover {
background: #f1f1f1; }
.chat-msg .action-btns .btn:focus {
outline: none;
-webkit-box-shadow: none;
box-shadow: none; }
.chat-msg .action-btns .btn .icon {
display: -webkit-inline-box;
display: -ms-inline-flexbox;
display: inline-flex;
width: 22px;
height: 22px;
 }
.chat-msg .action-btns .btn.reply-btn .icon {
background-position: -6px -262px; }
.chat-msg .action-btns .btn.forward-btn .icon {
background-position: -40px -262px; }
.chat-msg .action-btns .btn.bookmark-btn .icon {
background-position: -79px -262px; }
.chat-msg .action-btns .btn.edit-btn .icon {
background-position: -116px -262px; }
.chat-msg .action-btns .btn.emoji-btn .icon {
background-position: -153px -262px; }
.chat-msg .action-btns .btn.more-btn .icon {
background-position: -187px -261px; }
.chat-msg .action-btns .btn.more-btn:after {
display: none; }
.chat-msg .action-btns .more-dropdown .dropdown-menu {
background: #D9D9D9;
-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
border-radius: 6px;
min-width: 136px; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn {
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #424242; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn:hover {
background: #f1f1f1; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn .icon {
display: -webkit-inline-box;
display: -ms-inline-flexbox;
display: inline-flex;
width: 24px;
height: 24px;
;
margin-right: 8px; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn.newtask-btn .icon {
background-position: -223px -262px; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn.meeting-btn .icon {
background-position: -264px -262px; }
.chat-msg .action-btns .more-dropdown .dropdown-menu .btn.delete-btn .icon {
background-position: -306px -263px; }

.reply-wrapper {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between; }
.reply-wrapper .white_bg {
width: 49.8%;
display: block;
padding: 20px 16px 46px;
position: relative; }
.reply-wrapper .action-btns {
right: 4%; }
.reply-wrapper .chat-area-main {
padding: 0 20px; }
/* .app_mesage_body .message_typing_area{
	position: relative;
	height: 120px;
} */
.reply-wrapper .message_typing_area .chat-input {
height: auto;
position: absolute;
width: 100%;
left: 0;
bottom: 0;
padding: 20px 38px;
background: #fff;
-webkit-box-shadow: 0px -5px 4px rgba(0, 0, 0, 0.05);
box-shadow: 0px -5px 4px rgba(0, 0, 0, 0.05);
border-radius: 0 0 10px 10px; }
.reply-wrapper .message_typing_area .chat_icon_list {
width: calc(100% - 80px);
left: 0;
right: 0;
margin: auto;
bottom: 27px; }
.app_mesage_body .chat_icon_list {
    position: absolute;
    bottom: 2px;
    left: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    width: calc(100% - 3px);
    padding: 4px 10px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.reply-wrapper .replie-header {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
padding-bottom: 16px; }
.reply-wrapper .replie-header .content-blk label {
font-style: normal;
font-weight: 900;
font-size: 15px;
line-height: 18px;
font-family: "Lato", sans-serif;
margin-bottom: 3px; }
.reply-wrapper .replie-header .content-blk p {
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
font-family: "Lato", sans-serif;
color: rgba(0, 0, 0, 0.6); }
.reply-wrapper .replie-header .close-btn {
width: 24px;
height: 24px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
border: none;
background: none; }
.reply-wrapper .replie-header .close-btn .icon {

width: 16px;
height: 16px;
background-position: -442px -266px; }
.reply-wrapper .chat-area-main {
height: calc(100% - 156px);
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column; }
.reply-wrapper .replie-container {
margin-top: 0 !important;
margin-bottom: 0; }


.reply-wrapper .message-attachements .attachment{
	width: 48%;
}
.reply-msg {
width: 100%;
padding-left: 65px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
margin-bottom: 24px;
position: relative; }
.reply-msg:before {
position: absolute;
content: "";
background: #D6D6D6;
width: 4px;
height: 100%;
left: 55px; }
.reply-msg .header-blk {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
margin-bottom: 4px; }
.reply-msg .header-blk .user-img {
width: 16px;
height: 16px;
min-width: 16px;
border-radius: 4px; }
.reply-msg .header-blk label {
font-style: normal;
font-weight: 900;
font-size: 15px;
line-height: 18px;
margin-bottom: 0;
color: #242424;
padding-left: 10px;
font-family: "Lato", sans-serif; }
.reply-msg .reply-text {
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
font-family: "Lato", sans-serif;
color: #242424;
margin-bottom: 10px; }
.reply-msg .footer-blk {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center; }
.reply-msg .footer-blk p {
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #242424;
font-family: "Lato", sans-serif;
padding: 0 6px;
position: relative; }
.reply-msg .footer-blk p:after {
position: absolute;
content: "";
width: 1px;
height: 12px;
background: #D6D6D6;
left: 0;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%); }
.reply-msg .footer-blk p:first-child {
padding-left: 0; }
.reply-msg .footer-blk p:first-child:after {
display: none; }


.emoji-wrapper:after{
	display: none;
}
.emoji-wrapper:hover .emoji-list{
	top:-32px;
}


.app_mesage_body.reply-wrapper .message_typing_area{
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	padding: 20px 38px;
	background: #fff;
	-webkit-box-shadow: 0px -5px 4px rgb(0 0 0 / 5%);
	box-shadow: 0px -5px 4px rgb(0 0 0 / 5%);
	border-radius: 0 0 10px 10px;
	border:none;
	height: 140px;
}

.reply-wrapper .message_typing_area .inner-scrollbar{
    border: 1px solid #242424;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height:95px;
    padding: 14px 14px 40px;
    resize: none;
    position: relative;
}
.reply-wrapper .message_typing_area .chat-input{
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height:auto;
    padding:0;
    resize: none;
    position: relative;
    box-shadow: none;
}

.chat-reactions {
    position: absolute;
    right: 24px;
    top: 2px;
}

.chat-msg .chat-msg-content .user-detail{
	padding-right: 40px;
}
.chat-msg .action-btns .more-dropdown .dropdown-menu{
	background: #FFFFFF;
    border: 1px solid #D6D6D6;
    -webkit-box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
    border-radius: 6px;
    min-width: 105px;
    padding: 6px 0;
}
.app_mesage_body.reply-wrapper .white_bg:first-child  .chat-area-main {
    height: calc(100% - 112px);
}

.message_typing_area .inner-scrollbar{
	height: 100%;
    overflow-y: auto;
}
.bookmarked-btn{
	cursor: pointer;
}
.chat-msg .chat-msg-content .chat-desc, .bookmark-card .content-blk .desc{
	word-break: break-word;
}

.editable:empty:before {
    content: attr(placeholder);
}
.app_mesage_body  .disabled{
	pointer-events: none;
	opacity: 0.4;
}
.dm-card .left-ui.active p{
	font-weight: bold;
}
.hide-hover .user-list-link {
    background: transparent !important;
}
.deleted-message {
    font-size: 14px;
    font-style: italic;
    color: #9b9b9b;
    text-align: left;
}

.chat-msg.my-msg .deleted-message{
    text-align: right;
}
.chat-msg .status {
    color: #777;
    font-size: 11px;
    margin-top: 10px;
}
.user_removed {
    width: 100%;
    text-align: center;
    position: relative;
}

.user_removed:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #eee;
    position: absolute;
    left: 0;
    top: 13px;
}
.user_removed .removed_user{
    position: relative;
    z-index: 1;font-size: 14px;
    background: #fff;
    padding: 2px 10px;
}

.icon_img {
    width: 30px;
}
.chat-noti-item {
    background-color: #E4EBFF;
    border-radius: 70px;
    padding: 0px !important;
}
.notification.chat-noti-item {
    margin-right: 16px;
}
.my-list .reaction_list{
    right: 0px;
    left: auto;
}

.reaction_list{
    background-color: #ffffff;
    position: absolute;
    left: 0px !important;
    /* top: 25px !important; */
    z-index: 999 !important;
    padding: 10px !important;
    box-shadow: 0px 2px 10px #eee;
    border-radius: 10px;
    /* width: 100% !important; */
    min-width: 160px !important;
    opacity: 0;
    visibility: hidden;
    display: none;
}
.show{
    opacity: 1;
    visibility: visible;
    display: block;
}
.reaction_list .user-list{
    margin-top: 5px;
    max-height: 100px;
    overflow: auto;
    width: 100%;
}
.reaction_list .user-list-item{
    margin-bottom: 5px;
    border-bottom: 1px;
}
.reaction_list .user-img-wrpr{
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.reaction_list .user-img-wrpr img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    margin-right: 8px;
}
.reaction_list .user-img-wrpr .no-userpic {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 69, 255, 0.10);
    font-weight: 800;
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    color: #0045FF;
}
.reaction_list .user-img-wrpr .user-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.0048em;
    color: #797994;
    margin: 0;
}
.reaction-type-wrpr img{ 
    margin-right: 4px;
    width: 20px;
    height: 20px;
}
.small-font{
    font-size: 11px;
    color: #888;
}
.failed-msg {
    color: #f77575;
    font-size: 13px;
    text-align: right;
    position: relative;
    top: 12px;
}